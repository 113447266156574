import { LoyaltyEarningRule, Status } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';

import { SimpleRule } from '../../types/domain';
import { createRuleDescription } from '../create-rule-description';

export const toSimpleRules = (
  rules: LoyaltyEarningRule[],
  flowAPI: ControllerFlowAPI,
  currency: string,
  locale: string,
  loyaltyProgram: LoyaltyProgram,
): SimpleRule[] => {
  const rulesArray = rules
    .filter((rule) => rule.status === Status.ACTIVE)
    .map((rule) => {
      const result: SimpleRule = {
        title: rule.title ?? '',
        description: createRuleDescription(rule, flowAPI, currency, locale, loyaltyProgram),
      };
      return result;
    });
  return rulesArray;
};
