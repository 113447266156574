import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { getCouponSubtitle } from '@wix/loyalty-coupon-names';

import { Experiments } from '../../constants/experiments';

export const createRewardDescription = (
  loyaltyProgram: LoyaltyProgram,
  reward: Reward,
  flowAPI: ControllerFlowAPI,
  locale: string,
  currency: string,
  coupon?: CouponReference,
) => {
  let result;
  const displayName = coupon?.specification?.name;
  const { t } = flowAPI.translations;
  const useCouponSubtitlePackage = flowAPI.experiments.enabled(Experiments.UseCouponSubtitlePackage);

  switch (reward.type) {
    case RewardType.DISCOUNT_AMOUNT: {
      result = t('app.reward-description.discount-amount', {
        discountAmount: new Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(parseFloat(reward.discountAmount?.amount ?? '0')),
      });
      break;
    }
    case RewardType.COUPON: {
      if (useCouponSubtitlePackage) {
        result = getCouponSubtitle({ coupon: coupon!, locale, currency, t, referenceCoupon: true });
        break;
      } else {
        if (coupon?.specification?.moneyOffAmount && displayName) {
          result = t('app.reward-description.coupon.money-off-item', {
            price: new Intl.NumberFormat(locale, {
              style: 'currency',
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(coupon.specification.moneyOffAmount),
            displayName,
          });
          break;
        }

        if (coupon?.specification?.moneyOffAmount) {
          result = t('app.reward-description.coupon.money-off-all', {
            price: new Intl.NumberFormat(locale, {
              style: 'currency',
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(coupon.specification.moneyOffAmount),
          });
          break;
        }

        if (coupon?.specification?.percentOffRate && displayName) {
          result = t('app.reward-description.coupon.percentage-off-item', {
            amount: coupon.specification.percentOffRate,
            displayName,
          });
          break;
        }

        if (coupon?.specification?.percentOffRate) {
          result = t('app.reward-description.coupon.percentage-off-all', {
            amount: coupon.specification.percentOffRate,
          });
          break;
        }

        if (coupon?.specification?.freeShipping && displayName) {
          result = t('app.reward-description.coupon.free-shipping-item', { displayName });
          break;
        }

        if (coupon?.specification?.freeShipping && coupon.specification.minimumSubtotal) {
          result = t('app.reward-description.coupon.free-shipping-minimum', {
            price: new Intl.NumberFormat(locale, {
              style: 'currency',
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(coupon.specification.minimumSubtotal),
          });
          break;
        }

        if (coupon?.specification?.freeShipping) {
          result = t('app.reward-description.coupon.free-shipping-all');
          break;
        }

        if (coupon?.specification?.fixedPriceAmount && displayName) {
          result = t('app.reward-description.coupon.fixed-price-item', {
            price: new Intl.NumberFormat(locale, {
              style: 'currency',
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(coupon.specification.fixedPriceAmount),
            displayName,
          });
          break;
        }

        if (coupon?.specification?.fixedPriceAmount) {
          result = t('app.reward-description.coupon.fixed-price-all', {
            price: new Intl.NumberFormat(locale, {
              style: 'currency',
              currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }).format(coupon.specification.fixedPriceAmount),
          });
          break;
        }

        if (coupon?.specification?.buyXGetY && displayName) {
          result = t('app.reward-description.coupon.buy-x-get-y-item', {
            x: coupon.specification.buyXGetY.x,
            y: coupon.specification.buyXGetY.y,
            displayName,
          });
          break;
        }

        if (coupon?.specification?.buyXGetY) {
          result = t('app.reward-description.coupon.buy-x-get-y-all', {
            x: coupon.specification.buyXGetY.x,
            y: coupon.specification.buyXGetY.y,
          });
          break;
        }

        result = t('app.reward-description.empty');
      }
    }
    default: {
      result = t('app.reward-description.empty');
    }
  }

  const key = loyaltyProgram.pointDefinition?.customName
    ? 'app.reward-description.points-custom'
    : 'app.reward-description.points';
  return `${t(key, {
    count: reward.requiredPoints,
    pointsName: loyaltyProgram.pointDefinition?.customName,
  })} = ${result}`;
};
