import { CreateControllerFn, IWixAPI } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { LoyaltyProgram, ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { initLoyaltyCouponNames } from '@wix/loyalty-coupon-names';

import { SettingsEvents, TabState } from '../../types/settings';
import { ControllerProps } from './Widget';
import { toSimpleRules } from '../../utils/to-simple-rules';
import { toSimpleRewards } from '../../utils/to-simple-rewards';
import { loadData } from '../../utils/load-data';
import { createFixtures } from '../../utils/create-fixtures';
import { AppDefId } from '../../constants/app-def-ids';

const myRewardsSectionId = 'My Rewards';

const showCouponRewards = async (wixCodeApi: IWixAPI) => {
  const isStoresInstalled = await wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: AppDefId.Stores,
    sectionId: 'product_page',
  });

  const isEventsInstalled = await wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: AppDefId.Events,
    sectionId: 'events',
  });

  const isBookingsInstalled = await wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: AppDefId.Bookings,
    sectionId: 'bookings_list',
  });

  // const isPricingPlansInstalled = await wixCodeApi.site.isAppSectionInstalled({
  //   appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
  //   sectionId: 'membership_plan_picker_tpa'
  // })

  return isStoresInstalled || isEventsInstalled || isBookingsInstalled;
};

const createController: CreateControllerFn = async ({
  controllerConfig: { config, setProps, wixCodeApi },
  flowAPI,
}) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(config.publicData.COMPONENT || {});

  const setWidgetProps = (props: Partial<ControllerProps>) => {
    setProps(props);
  };

  return {
    async pageReady() {
      let program;
      let loyaltyProgram: LoyaltyProgram = {};
      componentEventHandler.on('tabState', (tabState: TabState) => setWidgetProps({ tabState }));
      componentEventHandler.onReset(() => setWidgetProps({ tabState: TabState.BeforeSignUp }));

      const isMyRewardsPageInstalled = await wixCodeApi.site.isAppSectionInstalled({
        sectionId: myRewardsSectionId,
        appDefinitionId: AppDefId.MyRewards,
      });

      try {
        try {
          await initLoyaltyCouponNames(flowAPI.translations.i18n);
          const { data } = await flowAPI.httpClient.request(getLoyaltyProgram({}));
          await initLoyaltyCouponNames(flowAPI.translations.i18n);

          if (data.loyaltyProgram) {
            loyaltyProgram = data.loyaltyProgram;
          }
        } catch (error) {
          throw error;
        }

        const isEditor = flowAPI.environment.isEditor;
        const isLoyaltyActive = loyaltyProgram.status === ProgramStatus.ACTIVE;

        const getData = async () => {
          if (isEditor && !isLoyaltyActive) {
            loyaltyProgram.status = ProgramStatus.ACTIVE;
            return await createFixtures(flowAPI, await showCouponRewards(wixCodeApi));
          }
          return await loadData(flowAPI);
        };
        const { coupons, earningRules, rewards } = await getData();

        program = loyaltyProgram;
        const navigateToMyRewards = async () => {
          const api = await wixCodeApi.site.getPublicAPI(AppDefId.SantaMembers);
          if (api) {
            api.navigateToSection?.({
              appDefinitionId: AppDefId.MyRewards,
              sectionId: myRewardsSectionId,
              memberId: wixCodeApi.user.currentUser.id,
            });
          }
        };

        const requestLogin = async () => {
          try {
            const user = await wixCodeApi.user.promptLogin({
              mode: 'login',
              modal: true,
            });
            if (user.loggedIn) {
              await navigateToMyRewards();
            }
          } catch (e) {
            // Ignore "authentication canceled"
          }
        };

        setWidgetProps({
          simpleRules: toSimpleRules(
            earningRules,
            flowAPI,
            wixCodeApi.site.currency ?? 'USD',
            wixCodeApi.site.regionalSettings ?? 'en-US',
            loyaltyProgram,
          ),
          simpleRewards: toSimpleRewards(
            rewards,
            coupons,
            flowAPI,
            wixCodeApi.site.currency ?? 'USD',
            wixCodeApi.site.regionalSettings ?? 'en-US',
            loyaltyProgram,
          ),
          loyaltyProgram,
          isLoggedIn: isEditor ? false : wixCodeApi.user.currentUser.loggedIn,
          isMyRewardsPageInstalled,
          navigateToMyRewards: async () => {
            if (flowAPI.environment.isViewer) {
              flowAPI.bi?.loyaltyJoinNowOrViewPoints({
                button: wixCodeApi.user.currentUser.loggedIn ? 'view_points' : 'join_program',
                role: wixCodeApi.user.currentUser.role,
              });
            }
            if (wixCodeApi.user.currentUser.loggedIn) {
              await navigateToMyRewards();
            } else {
              await requestLogin();
            }
          },
        });

        wixCodeApi.user.onLogin((user: any) => {
          setWidgetProps({ isLoggedIn: user.loggedIn });
        });
      } catch (e) {
        if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
          console.error(e);
        }
        flowAPI.reportError(e as Error);
        setWidgetProps({
          hasError: true,
        });
      }
      if (flowAPI.environment.isViewer) {
        flowAPI.bi?.loyaltyProgramOpen({
          response: program?.status,
          role: wixCodeApi.user.currentUser.role,
        });
      }
    },
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
  };
};

export default createController;
