import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';

import { SimpleReward } from '../../types/domain';
import { createRewardDescription } from '../create-reward-description';

export const toSimpleRewards = (
  rewards: Reward[],
  coupons: CouponReference[],
  flowAPI: ControllerFlowAPI,
  currency: string,
  locale: string,
  loyaltyProgram: LoyaltyProgram,
): SimpleReward[] => {
  const rewardsArray = rewards
    .filter((reward) => reward.active)
    .map((reward) => {
      const result: SimpleReward = {
        title: reward.name ?? '',
        description: '',
      };

      if (reward.coupon?.templateId) {
        const correctCoupon = coupons.find((coupon) => coupon.couponId === reward.coupon?.templateId);
        if (correctCoupon) {
          result.correctCouponFound = true;
          result.description = createRewardDescription(
            loyaltyProgram,
            reward,
            flowAPI,
            locale,
            currency,
            correctCoupon,
          );
        } else {
          result.correctCouponFound = false;
        }
      } else {
        result.description = createRewardDescription(loyaltyProgram, reward, flowAPI, locale, currency);
      }
      return result;
    })
    .filter((reward) => {
      if (reward.hasOwnProperty('correctCouponFound')) {
        return reward.correctCouponFound;
      }
      return true;
    });
  return rewardsArray;
};
